/**
 * Tests whether the given url is **currently** being routed to Next.js frontend
 */
export function isNextjsRoutable(pathname: string) {
  // Add more match expressions as we route more pages to Next.js
  return [
    /^\/$/, // /
    /^\/kohde\/\d+\/?$/, // /kohde/:id
    /^\/kohde\/\d+\/[a-zA-Z0-9-_]+\/?$/, // /kohde/:id/:slug
    /^\/(tee-tunnus|tee-tunnus\/valmis)$/, // /tee-tunnus && /tee-tunnus/valmis
    /^\/evasteet$/, // /evasteet
    /^\/osasto\/?(\?.*)?$/, // /osasto
    /^\/osasto\/(\d+)\/?(\?.*)?$/, // /osasto/:id
    /^\/osasto\/(\d+)\/[a-zA-Z0-9-_]+\/?(\?.*)?$/, // /osasto/:id/:slug
    /^\/osasto\/tapahtumat\/(\d+)\/?(\?.*)?$/, // /osasto/tapahtumat/:id
    /^\/haku\/?(\?.*)?$/, // /haku
    /^\/ilmoittaja\/[a-zA-Z0-9-_]+\/?(\?.*)?$/, // /ilmoittaja/:slug
    /^\/ilmoittaja\/[a-zA-Z0-9-_]+\/palautteet\/?$/, // /ilmoittaja/:slug/palautteet
    // TODO: Enable these when published
    // /^\/seurantalista\/?$/, // /seurantalista
  ].some((pattern) => pathname.match(pattern));
}

export function resolve(href: string) {
  return [
    resolveInternalUrl,
    // other link resolvers can be chained here
  ].reduce((result, fn) => fn(result), href);
}

function resolveInternalUrl(href: string) {
  try {
    const u = new URL(href.toString());
    if (['huutokaupat.com', 'huutokaupat.dev'].includes(u.host)) return `${u.pathname}${u.search}${u.hash}`;
  } catch (_) {
    return href;
  }
  return href;
}
